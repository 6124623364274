import axios from 'axios'
import hello from 'hellojs'
import dayjs from 'dayjs'
import storage from "../js/storage"
var instance = axios.create({
  timeout: 35000
});
// gmail 回复https://www.coder.work/article/6691323
function helloInit() {
  hello.init({
    // facebook: "362601571342205",
    facebook:"2897394323848049",
    google: "517653368983-l0um286giqgm2qkeot89f5snksrmbpp3.apps.googleusercontent.com"
  }, {
    redirect_uri: "/"
  });
}

// 
// Facebook 登录  517653368983-l0um286giqgm2qkeot89f5snksrmbpp3.apps.googleusercontent.com
export function facebookLogin() {
  return new Promise((resolve, reject) => {
    // hello 初始化
    helloInit();
    // facebook Login
    hello("facebook").login().then(res => {
      resolve(res);
    }, err => {
      reject(err);
    });
  });
}
// Google 登录
export function googleLogin() {
  return new Promise((resolve, reject) => {
    // hello 初始化
    helloInit();
    // google Login
    hello("google").login({
      response_type: "token id_token",
      scope: "email, https://www.googleapis.com/auth/gmail.send,https://www.googleapis.com/auth/gmail.readonly"
    }).then(res => {
      return hello("google").api("me");
    }, err => {
      reject(err);
    }).then(res => {
      // 保存 email
      sessionStorage.setItem("gmail_email", res.email);
      resolve(res);
    }, err => {
      reject(err);
    });
  });
}

/*
subject: 邮件主题（字符串）
content: 邮件内容（字符串）
to: 收件人，多个收件人使用 ; 分割（字符串）
cc: 抄送给谁，多个抄送收件人使用 ; 分割（字符串）
attachments: 附件（数组）
  数组中的对象格式
  {
    fileName: "文件名"
    fileType: "文件类型"
    data: "文件内容"
  }
 */
//  RESPONSE 响应异常拦截
// instance.interceptors.response.use(data => {
//   // 抛出错误
//   console.log(data,"data")
//   const err = new Error(data.description)
//   err.data = data
//   return data;
// }, err => {
//   console.log(err,"errs")
//   return Promise.resolve(err);
// })
// 发送邮件
function useGoogleEmail(subject, content, to, cc = "", attachments = []) {
  return new Promise((resolve, reject) => {
    let email;
    let api = "";
    let base64EncodedEmail;
    if(cc){
        email =
        "Content-Type: multipart/mixed; boundary=foo_bar_baz\r\n" +
        // "Content-Type: application/json; charset=UTF-8\r\n" +
        "from: me\r\n" +
        "to: " + to + "\r\n" +
        "cc: " + cc + "\r\n" + // 抄送给谁
        "subject: " + subject + "\r\n\r\n" +
        "--foo_bar_baz\r\n" +
        // "Content-Type: text/html\r\n" +
        content + "\r\n\r\n";
    }else {
      email =
      "Content-Type: multipart/mixed; boundary=foo_bar_baz\r\n" +
      // "Content-Type: application/json; charset=UTF-8\r\n" +
      "from: me\r\n" +
      "to: " + to + "\r\n"+
      "subject: " + subject + "\r\n\r\n" +
        "--foo_bar_baz\r\n" +
        // "Content-Type: text/html\r\n" +
        content + "\r\n\r\n";
    }
    // // 是否上传附件
    attachments.forEach((attachment) => {
      email +=
        // 如多个附件，重复拼接以下信息即可
        "--foo_bar_baz\r\n" +
        "Content-Type: " + attachment.fileType + "\r\n" +
        "Content-Transfer-Encoding: base64\r\n" +
        'Content-Disposition: attachment; filename="' +
        attachment.fileName + '"\r\n\r\n' +
        attachment.data + "\r\n\r\n";
    });
    if (attachments.length != 0) {
      api = "https://www.googleapis.com/upload/gmail/v1/users/me/messages/send?uploadType=multipart";
      base64EncodedEmail = email;
    } else {
      api = "https://www.googleapis.com/gmail/v1/users/me/messages/send";
      // The body needs to be base64url encoded.
      const encodedMessage = window.btoa(unescape(encodeURIComponent(email)));
      const reallyEncodedMessage = encodedMessage.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
      base64EncodedEmail = JSON.stringify({
        raw: reallyEncodedMessage
      });
      // base64EncodedEmail = JSON.stringify({
      //     raw: Base64.encodeURI(email)
      //   });
    }
    let google = JSON.parse(localStorage.getItem("hello"))["google"];
    instance.post(api,
      base64EncodedEmail, {
        headers: {
          "Content-Type": "multipart/rfc822",
          Authorization: google["token_type"] + ' ' + google["access_token"]
        }
      }
    ).then(res => {
      resolve(res);
    }, err => {
      reject(err);
    });

  })
}

export function checkToken() {
  // 有 email 和 token，且 token 有效
  try {
    let google = JSON.parse(localStorage.getItem("hello"))["google"];
    let email = sessionStorage.getItem("gmail_email");
    if (email && dayjs(parseInt(google["expires"]) * 2000) > dayjs()) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false
  }
}
export function sendEmail(subject, content, to, cc = "", attachments = []) {
  return new Promise((resolve, reject) => {
    if (checkToken()) {
      // console.log("Token 有效，直接发送邮件");
      useGoogleEmail(subject, content, to, cc, attachments).then(res => {
        resolve(res);
      }, err => {
        if (err.response && err.response.status === 401) {
          googleLogin().then(() => {
            return useGoogleEmail(subject, content, to, cc, attachments);
          }).then(res => {
            resolve(res);
          }).catch(err => {
            reject(err);
          });
        } else {
          reject(err);
        }

      });
    } else {
      googleLogin().then(() => {
        return useGoogleEmail(subject, content, to, cc, attachments);
      }).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    }

  });
}

// 回复邮件 https://developers.google.com/gmail/api/reference/rest/v1/users.messages/send  https://www.coder.work/article/6691323
function googleReplyEmail(subject, content, to, cc = "", attachments = [], references, message_id, in_Reply_To) {
  return new Promise((resolve, reject) => {
    let email;
    if (references === null||references === undefined || in_Reply_To === undefined||in_Reply_To === null) {
      if(cc){
        email =
        "Content-Type: multipart/mixed; boundary=foo_bar_baz\r\n" +
        "from: me\r\n" +
        "to: " + to + "\r\n" +
        "subject: " + subject + "\r\n\r\n" +
        "--foo_bar_baz\r\n" +
        // "Content-Type: text/html\r\n" +
        content +
        "\r\n\r\n";
    }else {
      email =
      "Content-Type: multipart/mixed; boundary=foo_bar_baz\r\n" +
      "from: me\r\n" +
      "to: " + to + "\r\n" +
      "cc: " + cc + "\r\n" + // 抄送给谁
      "subject: " + subject + "\r\n\r\n" +
      "--foo_bar_baz\r\n" +
      // "Content-Type: text/html\r\n" +
      content +
      "\r\n\r\n";
    }
    } else {
      if(cc){
        email =
        // "Content-Type: message/rfc822;\n" +
        "Content-Type: message/rfc822; boundary=\"foo_bar\"\n" +
        "References: " + references + " " + message_id + "\n" +
        "In-Reply-To: " + in_Reply_To + "\n" +
        "from: me\r\n" +
        "cc: " + cc + "\r\n" + // 抄送给谁
        "subject:" + subject + "\r\n\r\n" +
        // "--foo_bar_baz\r\n" +
        // "Content-Type: text/html\r\n" +
        content +"\r\n\r\n";
    }else {
      email =
        // "Content-Type: message/rfc822;\n" +
        "Content-Type: message/rfc822; boundary=\"foo_bar\"\n" +
        "References: " + references + " " + message_id + "\n" +
        "In-Reply-To: " + in_Reply_To + "\n" +
        "from: me\r\n" +
        "to: " + to + "\r\n" +
        "cc: " + cc + "\r\n" + // 抄送给谁
        "subject:" + subject + "\r\n\r\n" +
        // "--foo_bar_baz\r\n" +
        // "Content-Type: text/html\r\n" +
        content + "\r\n\r\n";
    }
    }
    const encodedMessage = window.btoa(unescape(encodeURIComponent(email)));
    const reallyEncodedMessage = encodedMessage.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
    let base64EncodedEmail = JSON.stringify({
      raw: reallyEncodedMessage
    });
    let google = JSON.parse(localStorage.getItem("hello"))["google"];
    instance.post("https://www.googleapis.com/gmail/v1/users/me/messages/send",
      base64EncodedEmail, {
        headers: {
          "Content-Type": "multipart/rfc822",
          Authorization: google["token_type"] + ' ' + google["access_token"]
        }
      },
    ).then(res => {
      resolve(res);
    }, err => {
      reject(err);
    });
  })

}
export function replyEmail(subject, content, to, cc = "", attachments = [], references, message_id, in_Reply_To) {
  return new Promise((resolve, reject) => {
    if (checkToken()) {
      // console.log("Token 有效，直接发送邮件");
      googleReplyEmail(subject, content, to, cc, attachments, references, message_id, in_Reply_To).then(res => {
        resolve(res);
      }, err => {
        if (err.response && err.response.status === 401) {
          googleLogin().then(() => {
            return useGoogleEmail(subject, content, to, cc, attachments);
          }).then(res => {
            resolve(res);
          }).catch(err => {
            reject(err);
          });
        } else {
          reject(err);
        }

      });
    } else {
      // console.log("没有 token 重新登录");
      googleLogin().then(() => {
        return googleReplyEmail(subject, content, to, cc, attachments, references, message_id, in_Reply_To);
      }).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    }

  });
}
// 下载文件
export function downloadFile(data, filename, type) {
  let blob;
  if (type) {
    blob = new Blob([data], {
      type: type
    });
  } else {
    blob = new Blob([data]);
  }
  let downloadElement = document.createElement("a");
  downloadElement.style.display = 'none';
  downloadElement.download = filename; // 下载后文件名
  let href = URL.createObjectURL(blob); // 创建下载的链接
  downloadElement.href = href;
  document.body.appendChild(downloadElement);
  downloadElement.click(); // 点击下载
  document.body.removeChild(downloadElement); // 下载完成移除元素
  URL.revokeObjectURL(href); // 释放掉blob对象
}
